import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../styles/main.scss"
import { propTypes } from "gatsby-plugin-image/dist/src/components/static-image.server"
import ArrowLink from "./arrow-link"

export default class GridTile extends React.Component {

    props: {
        node: any;
        frontmatter?: {
            childImageSharp: any
        }
    }
    location: string;
    cover: any;
    margin: number;
    gridSize: number;
    aspect: Array<number>;
    date: string;
    title: string;
    description: string;
    type: string;

    constructor(props) {
        super(props)

        this.margin = 5
        this.gridSize = 50

        // Determine type of node to process

        if (props.node.frontmatter) { // Node is a markdown document

            this.type = "document";

            let mdx = props.node

            if (mdx.frontmatter.cover) {
                this.cover = mdx.frontmatter.cover.childImageSharp.gatsbyImageData
            }
            this.location = mdx.slug
            this.title = mdx.frontmatter.title
            this.date = new Date(mdx.frontmatter.date).toLocaleDateString(
                'en-gb',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'utc'
                }
              );

        } else if (props.node.file) { // Node is an image file.

            let image = props.node.file.childImageSharp;
            this.type = "image";
            this.title = props.node.title

            this.location = `img/${props.node.id}`
            this.cover = image.gatsbyImageData

        }

        this.aspect = this.getAspect()
    }

    /**
     * 
     * 
     */
    private getAspect() {

        let ratio;
        let aspect = [1, 1]
        if (this.cover) {
            ratio = this.cover.height / this.cover.width;
        } else {
            ratio = Math.ceil(Math.random())
        }

        aspect[0] = Math.ceil(Math.max(4, Math.random()*6)) // Width
        aspect[1] = Math.ceil(Math.max(4,aspect[0]*ratio))

        return [aspect[0], aspect[1]]

    }

    public get_tile_info() {
        return (
            <div className={"tile-info"}>
                <span className={"tile-link"}><ArrowLink/></span>
                <span className={"tile-title"}>{this.title}</span>
                <span className={"tile-description"}>{this.date}</span>
            </div>
        )
    }

    public render() {
        let style = {
            width:  this.aspect[0]*this.gridSize + (2*this.margin * (this.aspect[0]-1)),
            height: this.aspect[1]*this.gridSize + (2*this.margin * (this.aspect[1]-1)),
        }

        return (
            <div className="grid-tile" style={style}>
                <Link to={this.location} className={"tile-content"}>
                    {this.type == "document" ? this.get_tile_info(): null}
                    <div className="tile-cover">
                        {this.cover ? <GatsbyImage alt={"Descriptive"} image={this.cover}/> : null}
                    </div>
                </Link>
            </div>
        )
    }
}