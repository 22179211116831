



import * as React from "react"

import "../styles/main.scss"

export default class ArrowLink extends React.Component {

    constructor(props) {
        super(props)
    }

    public render() {
        return (    
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path d="M4.925 19.075q-.25-.25-.25-.475 0-.225.25-.475l11.45-11.45H9.9q-.275 0-.475-.2-.2-.2-.2-.475 0-.275.2-.475.2-.2.475-.2h7.725q.45 0 .75.3t.3.75V14.1q0 .275-.2.475-.2.2-.475.2-.275 0-.475-.2-.2-.2-.2-.475V7.625L5.9 19.075q-.25.25-.487.25-.238 0-.488-.25Z"/>
            </svg>
        )
    }
}