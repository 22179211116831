import * as React from "react"
import { graphql, Link } from "gatsby"
import ArticleLink from "../components/article-link"
import Thumbnail from "../components/thumbnail"
import Logo from "../components/logo"
import Navbar from "../components/nav-bar"
import { Helmet } from "react-helmet";
import GridTile from "../components/grid-tile"
import Layout from "../layouts/layout"

export default class Index extends React.Component {
    props: {
        data: {
            site: {
                siteMetadata: {
                    siteTitle: string
                }
            }
            allMdx: any;
            allImageSharp: any
            allFile: any;
        }
    }
    constructor(props) {
        super(props)
    }

    public componentDidMount() {

        var grid = new Muuri('.works-grid', {
            showDuration: 600,
            layout: function (grid, layoutId, items, width, height, callback) {

                let worker = new Worker('layout_worker.js')

                let layout = {
                    id: layoutId, 
                    sizes: [],
                    slots: [],
                    styles: {},
                    items: null,
                    width: width,
                    height: height
                }

                for (let i of items) {
                    layout.sizes.push([i.getWidth(), i.getHeight()])
                }

                worker.postMessage(layout)

                worker.onmessage = function (e) {
                    
                    let computedLayout = e.data

                    computedLayout.items = items

                    callback(computedLayout)
                }
            }
        })
    }

    public render() {

        let dataSources = this.props.data.allMdx.nodes.concat(this.props.data.allJson.nodes)

        for (let node of dataSources) {
            if (node.file != undefined) {
                // Node is an image file
                node.weight = 0

            } else if (node.frontmatter != undefined) {
                node.weight = node.frontmatter.weight
            }
        }

        dataSources = dataSources.sort((a, b) => (a.weight < b.weight) ? 1 : -1)

        console.log(dataSources)

        let gridElements = [];
        for (let node of dataSources) {
            gridElements.push(<GridTile node={node}/>)
        }

        return (
            <Layout>
                <div className={"container"}>
                    <p>More coming, still under construction...</p>
                    <div className={"works-grid"}>
                        {gridElements}
                    </div>
                </div>
            </Layout>
        )
    }
}
export const pageQuery = graphql`
    query IndexQuery {   
        site {
            siteMetadata {
                siteTitle
            }
        }
        allMdx (filter: {
            frontmatter: {
                title: {nin: ["Contact Form", "About Me"]},
                publish: {eq: true}
            }
        }) {
            nodes {
                id
                slug
                frontmatter {
                    title
                    date
                    weight
                    cover {
                        childImageSharp {
                            gatsbyImageData (
                                width: 800,
                            )   
                        }
                    }
                }
            }
        }
        allJson {
            nodes {
              file {
                childImageSharp {
                  id
                  gatsbyImageData
                }
              }
              description
              title
              id
            }
          }
    }
`